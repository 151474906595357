import Head from 'next/head'
import HomeContainer from '@/containers/HomeContainer'
import { wrapper } from '@/redux/store/configureStore'
import { getHeadersForSSR } from '@/utils'
import { isUserSignedInServerSide } from '@/utils/general/user'
import { COMMON_SEO_TITLE } from '@/constants/components/common'

const Home: React.FC<{ isSignedIn: boolean, uhpCookie: boolean | string }> = ({ isSignedIn, uhpCookie }) => {
    return (
        <>
            <Head>
                <title>Cleartrip: #1 Site for Booking Flights Tickets & Hotels Online - Get Best Travel Deals</title>
                <meta
                    name="description"
                    content="Flight Booking & Hotel Booking made easy✔️Get amazing deals on International & Domestic Flight Tickets & choose from 5 lac+ Hotels. Get last minute hot deals."
                />
                <link rel="canonical" href="https://www.cleartrip.com/flights" />
                <link rel="alternate" hrefLang="en-in" href="https://www.cleartrip.com/flights" />
                <script
                    type="application/ld+json"
                    dangerouslySetInnerHTML={{
                        __html: JSON.stringify({
                            '@context': 'http://schema.org',
                            '@type': 'WebSite',
                            name: 'Cleartrip',
                            url: 'https://www.cleartrip.com/',
                            sameAs: [
                                'https://www.instagram.com/cleartrip',
                                'https://www.facebook.com/cleartrip',
                                'https://www.linkedin.com/company/cleartrip/',
                                'https://twitter.com/cleartrip'
                            ]
                        })
                    }}
                />
                <script
                    type="application/ld+json"
                    dangerouslySetInnerHTML={{
                        __html: JSON.stringify({
                            '@context': 'http://schema.org/',
                            '@type': 'Organization',
                            url: 'https://www.cleartrip.com/',
                            logo: 'https://storage.googleapis.com/ct-seo-waytogo-assets/waytogo-staging/new_images/cleartrip.jpg'
                        })
                    }}
                />
            </Head>
            <HomeContainer isSignedIn={isSignedIn}  uhpCookie={uhpCookie}/>
        </>
    )
}

export const getServerSideProps = wrapper.getServerSideProps(() => async context => {
    const { req } = context
    const cookies = req.cookies
    const signedIn = isUserSignedInServerSide(cookies)
    const uhpCookie = cookies['desktopUHP'] || 'false'

    //Add meta cookies to response.
    await getHeadersForSSR(context, {
        addUtmSourceInCookie: true
    })

    return {
        props: {
            isSignedIn: signedIn,
            uhpCookie: uhpCookie
        }
    }
})

export default Home
